import { Link } from 'gatsby';
import styled from 'styled-components';
import selectArrow from '../../static/images/landingPage/arrow_dropdown.png';
import selectDivider from '../../static/images/landingPage/divider.png';
import { deviceMax } from '../../styles/MediaQuery';
import {
    blue,
    blueDark,
    containerWidth,
    darkGreen,
    lightGray,
    limaBackground,
    midGreyBorder,
    white,
} from '../../styles/Variables';

export const Container = styled.footer`
    background: linear-gradient(135deg, ${blueDark} 0%, ${blueDark} 40%, ${blue} 60%, ${blue} 100%);
    min-height: 15rem;
    margin-top: 9rem;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @media (max-width: 1023px) {
        align-items: flex-start;
    }

    ::after {
        content: '';
        border-style: solid;
        border-width: 17px 30px 0 0;
        border-color: ${white} transparent transparent transparent;
        position: absolute;
        width: 70%;
        left: 0;
        top: -1px;
    }
`;

export const FooterContent = styled.div`
    color: ${white};
    display: flex;
    flex-direction: column;
    width: ${containerWidth};
    text-align: left;
    margin-top: 2rem;

    @media ${deviceMax.laptop} {
        width: 100%;
    }
`;

export const FooterNavigation = styled.div`
    display: flex;
    width: 100%;

    @media ${deviceMax.laptop} {
        flex-direction: column;
    }
`;

export const FooterNavigationLink = styled(Link)`
    color: ${white};
    line-height: 18px;
    text-decoration: none;
    margin-right: 1.4em;
    font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, sans-serif;
    font-weight: 400;
    box-sizing: border-box;

    @media ${deviceMax.laptop} {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        width: 100%;
        padding: 1em 0 1em 1em;

        &:last-child {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
    }
`;

export const FooterMedia = styled.div`
    display: flex;
    justify-content: space-between;

    @media ${deviceMax.laptop} {
        flex-direction: column;
        padding: 0 1em;
    }
`;

export const Newsletter = styled.div`
    padding-top: 2rem;
`;

export const NewsletterText = styled.p`
    font-family: 'Arial';
    font-size: 0.875rem;
    font-weight: 100;
    color: ${white};
    margin-bottom: 3px;
`;

export const NewsletterButton = styled(Link)`
    background: linear-gradient(to right, ${darkGreen} 0%, ${limaBackground} 100%);
    color: ${white};
    border-radius: 5px;
    border: 1px solid ${midGreyBorder};
    padding: 0.625rem 2rem;
    font-size: 0.875rem;
    line-height: 20px;
    float: left;
    text-align: center;
    text-decoration: none;
`;

export const FooterSocialMedia = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

export const FooterPartners = styled.div`
    padding-top: 2.3rem;

    a {
        font-family: 'Arial';
        font-size: 0.875rem;
        font-weight: 200;
        line-height: 1.2rem;
        color: ${white};
        text-decoration: none;
        margin-right: 1.25rem;
        display: flex;
        align-items: center;
    }

    @media ${deviceMax.laptop} {
        width: 100%;
    }
`;

export const FooterSocialMediaIcons = styled.div`
    padding-top: 2.3rem;
    display: flex;

    a:not(:first-child) {
        svg {
            margin-left: 0.6rem;
        }
    }

    a {
        svg {
            fill: ${white};
            font-size: 1.8rem;
        }
    }

    @media ${deviceMax.laptop} {
        width: 100%;
        justify-content: space-between;
    }
`;

export const FooterLinks = styled.div`
    margin-top: 3rem;
    margin-bottom: 1rem;
    color: ${white};
    font-size: 0.875rem;
    font-weight: 700;
    font-family: 'NeueHelveticaPro57Condensed', Helvetica, Arial;

    * {
        color: ${white};
        font-family: 'NeueHelveticaPro57Condensed', Helvetica, Arial;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
        cursor: pointer;
        text-decoration: none;
        margin: 0 0.3em;
    }
`;

export const FooterText = styled.div`
    background: ${blueDark};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FooterTextContent = styled.div`
    width: ${containerWidth};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    span {
        font-family: 'NeueHelveticaPro57Condensed', Helvetica, Arial;
        font-size: 1rem;
        color: ${white};
        text-transform: uppercase;
        font-weight: 700;
        margin: 1em 0;
    }

    @media ${deviceMax.laptop} {
        width: 100%;
        margin: 0 1em;
    }

    @media ${deviceMax.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const Select = styled.select`
    background: url(${selectDivider}) no-repeat 84% 43%, url(${selectArrow}) no-repeat 93% 45%,
        linear-gradient(180deg, #fafbfc 30%, #eff2f5 90%);
    border: 1px solid ${lightGray};
    cursor: pointer;
    padding-left: 0.8rem;
    margin: 1em 0;
    width: 210px;
    height: 2.5rem;
    color: ${darkGreen};
    font-size: 15px;
    border-radius: 5px;
    appearance: none;
    outline: none;

    option {
        color: ${darkGreen};
    }

    @media ${deviceMax.tablet} {
        width: 100%;
        margin-top: 0;
    }
`;
