import { createGlobalStyle } from 'styled-components';
import NeueHelveticaPro57Condensed from '../static/fonts/NeueHelveticaPro57Condensed/NeueHelveticaPro57Condensed.woff';
import NeueHelveticaPro77CondensedBold from '../static/fonts/NeueHelveticaPro77CondensedBold/NeueHelveticaPro77CondensedBold.woff';
import { black } from './Variables';

const Typography = createGlobalStyle`
    @font-face {
        font-family: 'NeueHelveticaPro57Condensed';
        src: url(${NeueHelveticaPro57Condensed});
        font-display: swap;
    }
    @font-face {
        font-family: 'NeueHelveticaPro77CondensedBold';
        src: url(${NeueHelveticaPro77CondensedBold});
        font-display: swap;
    }
    * {
        font-family: Arial, sans-serif;
        font-size: 16px;
        color: ${black};
        a {
            text-decoration: none;
        }
    }
    body {
        font-family: Arial, sans-serif;
        overflow-x: hidden;
        h1 { 
            font-family: "NeueHelveticaPro57Condensed",Helvetica,Arial;
        }
        h2 {
            font-family: "NeueHelveticaPro57Condensed",Helvetica,Arial;
            font-size: 15px;
            letter-spacing: .025em;
        }
    }
`;

export default Typography;
