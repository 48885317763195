import styled from 'styled-components';
import { citronBackground, limaBackground, white } from '../../../styles/Variables';

interface MenuItemProps {
    expanded: boolean;
    width: number;
}

export const MenuItem = styled.div<MenuItemProps>`
    width: ${({ width, expanded }) => (expanded ? `${width}px` : '75px')};
    min-width: ${({ expanded }) => (expanded ? '240px' : '75px')};
    max-width: 300px;
    height: 50px;
    background: linear-gradient(to right, ${limaBackground} 0%, ${citronBackground} 100%);
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    border: none;
    outline: none;
    text-align: left;
    padding: 0;
`;

interface MenuTitleProps {
    visible: boolean;
}

export const MenuTitle = styled.p<MenuTitleProps>`
    flex: 1;
    margin-left: ${({ visible }) => (visible ? '28px' : 0)};
    margin-right: 80px;
    font-family: 'NeueHelveticaPro57Condensed', Arial, Helvetica;
    font-size: 0.9375rem;
    font-weight: 700;
    text-transform: uppercase;
    color: ${white};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: opacity ${({ visible }) => (visible ? '0.5s' : '0s')} ease ${({ visible }) => (visible ? '0.5s' : '0s')};
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
`;

export const MenuIcon = styled.img`
    flex: 1;
    width: 40px;
    margin-right: 15px;
    pointer-events: none;
    position: absolute;
    right: 0;
`;
