import { Link } from 'gatsby';
import { FaThLarge } from 'react-icons/fa';
import styled from 'styled-components';
import { deviceMin } from '../../../styles/MediaQuery';
import { blue, blueDark, greyBorder, greyLight, white } from '../../../styles/Variables';
import ConstNavLink from './ConstNavLink';

export const Navigation = styled.ul`
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 0px 0 15px;
    list-style: none;
`;

export interface NavigationLinkProps {
    readonly isSelected: boolean;
}

export const NavigationLink = styled(ConstNavLink)<NavigationLinkProps>`
    font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, sans-serif;
    padding: 0.9em 1.3rem;
    font-size: 1.13em;
    font-weight: 400;
    text-decoration: none;
    transition: background-color color 0.25s;
    color: ${blue};
    background-color: ${({ isSelected }) => (isSelected ? greyLight : 'transparent')};

    &:hover {
        background-color: ${greyLight};
        color: ${blueDark};
    }
`;

export const AccordionMenu = styled.div`
    position: absolute;
    margin-top: 0.9em;
    margin-left: -0.7em;
    max-height: 0;
    width: fit-content;
    overflow: hidden;
    transition: max-height 0.01s;
    padding: 0;
    background-color: ${greyLight};
    display: flex;
    flex-wrap: wrap;
    z-index: 10;
`;

export const AccordionListWrapper = styled.div`
    display: flex;
`;

export const AccordionList = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    background-color: ${greyLight};
    min-width: 230px;

    &:not(:first-child) {
        border-left: 1px solid ${greyBorder};
    }
`;

export const AccordionTitle = styled.li`
    padding: 0.5em;
    font-weight: 700;
    font-size: 0.9em;
    font-family: 'NeueHelveticaPro57Condensed', Helvetica, Arial;
    background-color: ${greyLight};
`;

export const AccordionItem = styled.li`
    padding: 0.5em;
    background-color: ${greyLight};
    font-family: 'NeueHelveticaPro57Condensed', Helvetica, Arial;
`;

export const NavigationItem = styled.li`
    position: relative;
    font-family: 'NeueHelveticaPro57Condensed', Helvetica, Arial;

    &:hover ${AccordionMenu} {
        padding: 0.8em;
        max-height: 500px;
        transition: max-height 0.7s;
    }

    @media ${deviceMin.laptop} {
        &:nth-last-child(1) {
            ${AccordionMenu} {
                right: 0;
            }
        }
    }

    @media ${deviceMin.laptop} and (max-width: 1940px) {
        &:nth-last-child(2) {
            ${AccordionMenu} {
                right: 0;
            }
        }
    }

    @media ${deviceMin.laptop} and (max-width: 1700px) {
        &:nth-last-child(3) {
            ${AccordionMenu} {
                right: 0;
            }
        }
    }
`;

export const AccordionLink = styled(Link)`
    text-decoration: none;
    font-size: 0.9em;
    font-family: 'NeueHelveticaPro57Condensed', Helvetica, Arial;
    word-break: break-word;
`;

export const AccordionOverviewLink = styled(AccordionLink)`
    width: 100%;
`;

export const AccordionExternalLink = styled.a`
    text-decoration: none;
    font-size: 0.9em;
    font-family: 'NeueHelveticaPro57Condensed', Helvetica, Arial;
`;

export const AccordionOverview = styled.div`
    width: calc(1.6em + 100%);
    background-color: ${white};
    padding: 0.5em;
    margin: 0 -0.8em -0.8em -0.8em;
`;

export const ThIcon = styled(FaThLarge)`
    font-size: 1.5em;
`;
